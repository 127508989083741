
@font-face {
    font-family: 'SF_HambakSnow';
    src: url('SFTTF.ttf') format('woff');
  }
  

  @font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Chosunilbo_myungjo';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/Chosunilbo_myungjo.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
/* HG */
.aboutusCon {
    padding-left: 15px;
    padding-right: 15px;
}

.menuFont {
    font-family: 'SF_HambakSnow';
}

.textSpacing {
    word-spacing : -1px;
}

.textSpacings {
    word-spacing : -1px;
    font-family: 'Chosunilbo_myungjo';
}

.listSty{
    font-size: 1.rem;
    padding: 5px 10px;
    border-bottom: 1px solid rgb(36, 42, 116);
}




/* HY */
.aboutbanner {
    position: relative;
    display: flex;
    width: 100%; 
    height: 260px;
    padding-top: 40px;
    background-image: url('../asset/h5.jpg');
    background-size: cover;
    background-position: center;
}

.aboutbannerMobile {
    position: relative;
    display: flex;
    width: 100%; 
    height: 190px;
    padding-top: 60px;
    background-image: url('../asset/h5.jpg');
    background-size: cover;
    background-position: center;
}

.aboutbanner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.aboutbannerMobile::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.aboutus-title {
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
    padding: 0px 10px;
  }
  
  .aboutus-title h1 {
    text-align: left;
    padding-top: 20px;
    font-size: 1.8rem;
    font-family: 'SF_HambakSnow';
    color: #2C2C2C;
  }

  .aboutus-content {
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
    padding: 10px;
  }

  .aboutus-content p {
    text-align: left;
    padding-bottom: 30px;
    font-size: 0.9rem;
    font-weight: bold;
    font-family: 'Chosunilbo_myungjo';
    color: #2C2C2C;
  }

  .pad {
    padding: 0px 10px 10px 10px;
    padding-bottom: 60px;
  }


  .aboutus-content h3 {
    text-align: left;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'Pretendard-Regular';
    color: #171D75;
  }

.processbanner {
    position: relative;
    display: flex;
    width: 100%; 
    height: 260px;
    padding-top: 40px;
    background-image: url('../asset/h6.jpg');
    background-size: cover;
    background-position: center;
}

.processbanner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.processbannerMobile {
    position: relative;
    display: none;
    width: 100%; 
    height: 190px;
    padding-top: 60px;
    background-image: url('../asset/h6.jpg');
    background-size: cover;
    background-position: center;
}

.processbannerMobile::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.contactusbanner {
    position: relative;
    display: flex;
    width: 100%; 
    height: 260px;
    padding-top: 40px;
    background-image: url('../asset/contactus.jpg');
    background-size: cover;
    background-position: center;
}

.contactusbannerMobile {
    position: relative;
    display: none;
    width: 100%; 
    height: 190px;
    padding-top: 60px;
    background-image: url('../asset/contactus.jpg');
    background-size: cover;
    background-position: center;
}


.contactusbanner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.contactusbannerMobile::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.ourproductbanner {
    position: relative;
    display: flex;
    width: 100%; 
    height: 260px;
    padding-top: 40px;
    background-image: url('../asset/ourproduct.jpg');
    background-size: cover;
    background-position: center;
}

.ourproductbannerMobile {
    position: relative;
    display: none;
    width: 100%; 
    height: 190px;
    padding-top: 60px;
    background-image: url('../asset/ourproduct.jpg');
    background-size: cover;
    background-position: center;
}

.ourproductbanner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.ourproductbannerMobile::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.inquirybanner {
    position: relative;
    display: flex;
    width: 100%; 
    height: 260px;
    padding-top: 40px;
    background-image: url('../asset/inquirybanner.jpg');
    background-size: cover;
    background-position: center;
}

.inquirybannerMobile {
    position: relative;
    display: none;
    width: 100%; 
    height: 190px;
    padding-top: 60px;
    background-image: url('../asset/inquirybanner.jpg');
    background-size: cover;
    background-position: center;
}

.inquirybanner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.inquirybannerMobile::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.aboutus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0;
    position: relative;
    z-index: 2;
}

.aboutt {
    margin: 50px 0px;
    padding: 0;
    display: block;
}

.abouttMobile {
    margin: 10px;
    padding: 0;
    display: none;
}

.abouttMobileP {
    margin: 10px;
    padding: 0;
    display: block;
    width: 1000px;
}

.abou {
    margin: 0;
    padding: 0;
    display: block;
    max-width: 100%;
    height: auto;
}


.aboutuss {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0;
    position: relative;
    z-index: 2;
}

.aboutt {
    margin: 50px 0px;
    padding: 0;
}

.abou {
    margin: 0;
    padding: 0;
    display: block;
    max-width: 100%;
    height: auto;
}




.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 50px 0;
}

.stylish-button {
  padding: 5px 40px;
  font-size: 2rem;
  color: white;
  background: #b6b6b6;
  border: none;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
}

.stylish-button:hover {
  background: #363636;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.clicked {
    background: #363636;
}

.ourprdMobile {
    display: flex;
    margin: 10px 5px;
}

.productImg {
    flex: 3;
    /* border: 1px solid rgb(36, 42, 116); */
    padding: 5px;
    display: flex;
}

.productImg span {
    margin: auto;
    margin-top: 0;
}

.productImg span > img {
    width: 100%;
}

.productDes {
    flex: 7;
    display: block;
    margin: 0px 5px;
}

.prd-title {
    font-size: 1.6rem;
    /* padding: 5px 10px; */
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(0, 0, 0);
}

.prd-cate {
    font-size: 1.2rem;
    /* padding: 5px 10px; */
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid rgb(0, 0, 0);
}

.prd-des {
    font-size: 1rem;
    /* padding: 5px 10px; */
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid rgb(0, 0, 0);
    color: #6e6e6e;
}

.prd-des .desc-line {
    display: block;
    padding-left: 0rem; 
    text-indent: -0.3rem; 
    margin-left: 0.5rem;
  }

.pu {
    color:rgb(78 195 220);
}

.pur {
    border-top: 1px solid rgb(78 195 220);
}

.borderP {
    border-bottom: 1px solid rgb(78 195 220);
}

@media (max-width: 1024px) {

    .abouttMobileP {
        width: 100%;
    }

}

@media (max-width: 768px) {

    .button-container {
        margin: 20px 0;
    }

    .stylish-button {
        padding: 10px 20px;
        font-size: 1.6rem;
    }

    .processbanner{
        display: none;
    }

    .processbannerMobile{
        display: flex;
    }

    .ourproductbanner {
        display: none;
    }

    .ourproductbannerMobile {
        display: flex;
    }

    .inquirybanner {
        display: none;
    }

    .inquirybannerMobile {
        display: flex;
    }

    .contactusbanner {
        display: none;
    }

    .contactusbannerMobile {
        display: flex;
    }


    .aboutt {
        display: none;
    }

    .abouttMobile {
        display: block;
    }



.prd-title {
    font-size: 1.2rem;
}

.prd-cate {
    font-size: 1rem;
}

.prd-des {
    font-size: 0.8rem;
}
    
}


@media (max-width: 484px) {
    .productImg {
        flex: 2.5;
    }
    
    .productDes {
        flex: 6;
    }
    .prd-title {
        font-size: 0.8rem;
    }
    
    .prd-cate {
        font-size: 0.7rem;
    }
    
    .prd-des {
        font-size: 0.6rem;
    }
}


@media (max-width: 484px) {
    .productImg-hg {
        flex: 2.5;
    }
    
    .productDes-hg {
        flex: 6;
    }
    .prd-title-hg {
        font-size: 0.8rem;
    }
    
    .prd-cate-hg {
        font-size: 0.7rem;
    }
    
    .prd-des-hg {
        font-size: 0.6rem;
    }
}