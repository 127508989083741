
@font-face {
  font-family: 'SF_HambakSnow';
  src: url('SFTTF.ttf') format('woff');
}


.main2 {
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;
  background: linear-gradient(180deg, #f2f2f2, #f1f1f1);
  padding: 60px 0px;
}

.main2-heading h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #2C2C2C;
  font-family: 'SF_HambakSnow';
  padding: 60px 0px;
}

.carousel {
  width: 100%;
  height: 500px;
  display: flex;
  max-width: 1400px;
  max-height: 550px;
  overflow: hidden;
  position: relative;
  margin: auto;
}

.carousel-item {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  transition: 0.6s all ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
}

.carousel-item.active {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

.carousel-item__info {
  width: 40%;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateY(25%);
  opacity: 0;
  visibility: hidden;
  transition: 0.6s all ease-in-out;
}

.carousel-item.active .carousel-item__info {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.carousel-item__image {
  width: 60%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateX(100%);
  transition: 0.6s all ease-in-out;
}

.carousel-item.active .carousel-item__image {
  transform: translateX(0);
}

.carousel-item__subtitle {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 3px;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0;
  color: #7E7E7E;
  font-weight: 700;
}

.carousel-item__title {
  margin: 15px 0 0 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  line-height: 45px;
  letter-spacing: 3px;
  font-weight: 700;
  color: #2C2C2C;
}

.carousel-item__description {
  margin-top: 35px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #7e7e7e;
  line-height: 22px;
  margin-bottom: 35px;
}

.carousel-item__btn {
  color: #2C2C2C;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 3px;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  width: 35%;
  transform: translateY(25%);
  opacity: 0;
  visibility: hidden;
  transition: 0.6s all ease-in-out;
}

.carousel-item.active .carousel-item__btn {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.carousel__nav {
  position: absolute;
  right: 0;
  z-index: 2;
  background-color: #fff;
  bottom: 0;
  display: flex;
}

.carousel__icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  fill: #5d5d5d;
}

.carousel__arrow {
  cursor: pointer;
  display: inline-block;
  padding: 11px 15px;
  position: relative;
}

.carousel__arrow:nth-child(1):after {
  content: '';
  right: -3px;
  position: absolute;
  width: 1px;
  background-color: #b0b0b0;
  height: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-item--1 .carousel-item__image {
  background-image: url('../asset/n1.jpg');
}

.carousel-item--2 .carousel-item__image {
  background-image: url('../asset/n2.jpg');
}

.carousel-item--3 .carousel-item__image {
  background-image: url('../asset/n3.jpg');
}

@media (max-width: 768px) {
  .main2-heading h1 {
    font-size: 2rem;
    padding: 0px 0px 20px 0px;
  }

  .carousel-item__info {
    width: 50%;
    padding: 0 20px;
  }

  .carousel-item__image {
    width: 50%;
  }

  .carousel-item__title {
    font-size: 1.5rem;
    line-height: 36px;
  }

  .carousel-item__subtitle {
    font-size: 1rem;
  }

  .carousel-item__description {
    font-size: 0.8rem;
    line-height: 18px;
  }

  .carousel-item__btn {
    font-size: 0.8rem;
    width: 50%;
  }
}

@media (max-width: 480px) {
  .main2-heading h1 {
    font-size: 1.5rem;
  }
  .carousel-item__title {
    font-size: 1.2rem;
    line-height: 18px;
  }
}