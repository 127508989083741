
@font-face {
  font-family: 'SF_HambakSnow';
  src: url('SFTTF.ttf') format('woff');
}

    .main3 {
      width: 100%;
      height: auto;
      text-align: center;
      position: relative;
      background: linear-gradient(0deg, #ffffff, #f1f1f1);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .main3-inner {
      max-width: 1400px;
      height: auto;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;
      margin-bottom: 200px;
    }
    
    .main3-heading {
      padding: 50px 0px;
      margin-bottom: 20px;
    }
  
    .main3-heading h1 {
      font-weight: bold;
      font-size: 2.5rem;
      font-family: 'SF_HambakSnow';
      color: #2C2C2C;
    }
    
    .main3-grid-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1px;
      max-width: 1400px;
    }
    
    .main3-grid-item {
      height: auto;
      overflow: hidden;
      border-radius: 8px;
      margin: 5px;
    }
  
    .main3-item {
      max-width: 100%;
      transition: 0.5s;
    }
    .main3-item:hover {
      transform: scale(1.1);
      cursor: pointer;
      transition: 0.5s;
    }
    
    
    @media (max-width: 1024px) {
      .main3-grid-container {
        grid-template-columns: repeat(2, 1fr);
        margin: 20px;
      }
      .main3-inner {
        margin-bottom: 100px;
      }
    }
    
    @media (max-width: 768px) {
      .main3-inner {
        max-width: 100%;
      }
      .main3-grid-container {
        grid-template-columns: repeat(2, 1fr);
      }
      .main3-inner {
        margin-bottom: 50px;
      }

      .main3-heading {
        padding: 0px;
        margin-bottom: 0px;
      }

      .main3-heading h1 {
        font-size: 2rem;
      }

      .main3-item:hover {
        transform: scale(1.0);
    }
  }
    
    @media (max-width: 480px) {
      .main3-grid-container {
          grid-template-columns: repeat(2, 1fr);
      }
      .main3-inner {
        margin-bottom: 0px;
      }

      .main3-heading h1 {
        font-size: 1.5rem;
      }
    }


    
    .main3-slider-container {
      width: 90%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 0px;
  }
  
  .main3-slider-item {
      display: flex !important;
      justify-content: center;
      gap: 10px;
      margin: 10px 0px;
      cursor: pointer;
  }

  .main3-slider-item span {
    display: flex !important;
  }
  
  .main3-slider-item img {
      width: 95%;
      margin: auto;
      border-radius: 8px;
  }
  
  .slick-dots li button:before {
      color: gray !important;
  }