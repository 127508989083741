.register-us-container {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    height: 100%;
    position: relative;
    background-color: #f4f4f4;
    padding: 20px;
    height: auto;
  }
  
  .register-us-section {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .register-us-section-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
  }
  
  .register-us-form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .register-us-form-group {
    flex: 1 1 calc(50% - 20px);
    display: flex;
    flex-direction: column;
  }
  
  .register-us-form-group label {
    font-size: 1rem;
    color: #666;
    margin-bottom: 5px;
  }
  
  .register-us-form-group input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s ease;
  }
  
  .register-us-form-group input:focus {
    border-color: #2575fc;
    outline: none;
  }
  
  .register-us-submit-button {
    padding: 12px 30px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #33a924;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .register-us-submit-button:hover {
    background-color: #367f23;
  }

  .file-upload-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
  }
  
  .custom-file-upload {
    background-color: #d9d9d9;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    display: inline-block;
  }
  
  .custom-file-upload:hover {
    background-color: #f5f5f5;
    color: rgb(66, 66, 66);
  }
  
  #file-upload {
    display: none;
  }
  
  .file-name {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {

    .register-us-form-group {
      flex: 1 1 100%;
    }
    
    .register-us-section-title {
      font-size: 1.3rem;
    }
  
    .register-us-submit-button {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
  }